import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Node } from "@xyflow/react";
import { Input, InputNumber, Popover, Select } from "antd";
import Empty from "antd/lib/empty";
import { produce } from "immer";
import { pick } from "lodash";
import * as R from "ramda";
import { useContext, useEffect, useState } from "react";
import { v5 as uuidv5 } from "uuid";
import { useICState } from "../../state";
import { Context, SContent } from "../components";
import { hasOutput, MappingField } from "../utils";

const NAMESPACE = "00000000-0000-0000-0000-000000000000";

type Props = {
  id: string;
};

type State = {
  timeLevel: string;
  missingThreshold: { type: "QUANTITY" | "PERCENTAGE"; value: number };
  cvThreshold: number;
  level: MappingField[];
  formula: MappingField[];
};

const initialState: State = {
  timeLevel: "",
  missingThreshold: { type: "PERCENTAGE", value: 0.1 },
  cvThreshold: 1.5,
  level: [],
  formula: [],
};

const AnalysisWindow: React.FC<Props> = ({ id }) => {
  const { nodes, edges, setNodes } = useICState();
  const { actions } = useContext(Context);

  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    actions.save = () => {
      setNodes(
        produce((nodes: Node[]) => {
          const node = nodes.find((node) => node.id === id);

          node.data.missingThreshold = state.missingThreshold;
          node.data.cvThreshold = state.cvThreshold;
          node.data.timeLevel = state.timeLevel;
          node.data.level = state.level;
          node.data.formula = state.formula;
        }),
      );
    };
  });

  const setStateKeyValue =
    (key: string) => (value: any | ((value: any) => any)) => {
      setState(
        produce((state) => {
          state[key] = typeof value === "function" ? value(state[key]) : value;
        }),
      );
    };

  const setTimeLevel = setStateKeyValue("timeLevel");
  const setMissingThreshold = setStateKeyValue("missingThreshold");
  const setCVThreshold = setStateKeyValue("cvThreshold");
  const setLevel = setStateKeyValue("level");
  const setFormula = setStateKeyValue("formula");

  useEffect(() => {
    const node = nodes.find((node) => node.id === id)!;

    setState(
      produce((state) => {
        state.missingThreshold =
          node.data.missingThreshold ?? initialState.missingThreshold;
        state.cvThreshold = node.data.cvThreshold ?? initialState.cvThreshold;
        state.timeLevel = node.data.timeLevel ?? initialState.timeLevel;
        state.level = node.data.level ?? initialState.level;
        state.formula = node.data.formula ?? initialState.formula;
      }),
    );

    const fields: MappingField[] = [];

    const edge = edges.find((edge) => node.id === edge.target);
    const sourceNode = edge
      ? nodes.find((node) => edge.source === node.id)
      : undefined;

    if (sourceNode && hasOutput(sourceNode)) {
      ((sourceNode.data.formula as MappingField[]) ?? []).forEach((field) => {
        const findF = [...((node.data.level as MappingField[]) ?? [])].find(
          (f) => f.id === field.id,
        );
        const oldF = pick(field, ["uuid", "id", "type", "desc"]);
        const newF = findF ? { ...oldF, ...findF } : oldF;
        fields.push(newF);
      });
    }

    setLevel(fields);

    setFormula([
      ...fields.filter((f) => f.fieldType === "ATTRIBUTE"),
      {
        uuid: uuidv5("TSTYPE", NAMESPACE),
        id: "TSTYPE",
        desc: `Тип временного ряда`,
        type: "STRING",
      },
      {
        uuid: uuidv5("TRENDSLOPE", NAMESPACE),
        id: "TRENDSLOPE",
        desc: `Коэффициент тренда`,
        type: "DECIMAL",
      },
      {
        uuid: uuidv5("TRENDPVALUE", NAMESPACE),
        id: "TRENDPVALUE",
        desc: `Значимость тренда (p-value)`,
        type: "DECIMAL",
      },
    ]);
  }, [id]);

  const makeLevelUpdater = (uuid: string, key: string) => (value: any) => {
    setLevel(
      produce((fs: any[]) => {
        fs.forEach((f) => {
          if (f.uuid === uuid) {
            f[key] = value;
          }
        });
      }),
    );
  };

  const figureSelected = !!state.level.find((f) => f.fieldType === "FIGURE");
  const dateSelected = !!state.level.find((f) => f.fieldType === "DATE");

  return (
    <SContent>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "200px", fontWeight: "bold" }}>
            Временная гранулярность
          </div>
          <Select
            style={{ width: "300px", color: "black" }}
            options={PERIODS}
            value={state.timeLevel}
            onChange={setTimeLevel}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            width: "100%",
          }}
        >
          <div style={{ width: "200px", fontWeight: "bold" }}>
            Тип временного ряда
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "15px",
              alignItems: "center",
            }}
          >
            <div style={{ width: "200px" }}>
              Пороговое значение количества пропусков
            </div>
            <Select
              style={{ width: "300px" }}
              options={MISSING_THRESHOLD_TYPES}
              value={state.missingThreshold.type}
              onChange={(type) =>
                setMissingThreshold({ ...state.missingThreshold, type })
              }
            />
            <InputNumber
              style={{ width: "100px" }}
              value={state.missingThreshold.value}
              onChange={(value) =>
                setMissingThreshold({ ...state.missingThreshold, value })
              }
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "15px",
              alignItems: "center",
            }}
          >
            <div style={{ width: "200px" }}>
              Пороговое значение коэффициента вариации
            </div>
            <InputNumber
              style={{ width: "300px" }}
              value={state.cvThreshold}
              onChange={(value) => setCVThreshold(value)}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "50px",
            height: "500px",
            overflow: "auto",
          }}
        >
          <div>
            <span style={{ fontWeight: "bold" }}>Входящий поток</span>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "20px",
                      padding: "10px",
                      height: "32px",
                    }}
                  ></TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "100px",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Идентификатор
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "250px",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Описание
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "100px",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Тип
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "100px",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Тип поля
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.level?.length > 0 ? (
                  state.level.map((f, i) => (
                    <TableRow key={i}>
                      <TableCell style={{ padding: "10px", height: "32px" }}>
                        {f.fieldType ? "✅" : "⭕"}
                      </TableCell>
                      <TableCell style={{ padding: "10px", height: "25px" }}>
                        <Popover content={f.id}>
                          <Input
                            value={f.id}
                            onChange={R.pipe(
                              R.path(["target", "value"]),
                              makeLevelUpdater(f.uuid, "id"),
                            )}
                            disabled
                          />
                        </Popover>
                      </TableCell>
                      <TableCell style={{ padding: "10px", height: "25px" }}>
                        <Popover content={f.desc}>
                          <Input
                            value={f.desc}
                            onChange={R.pipe(
                              R.path(["target", "value"]),
                              makeLevelUpdater(f.uuid, "desc"),
                            )}
                            disabled
                          />
                        </Popover>
                      </TableCell>
                      <TableCell style={{ padding: "10px", height: "25px" }}>
                        <Popover content={f.type}>
                          <Select
                            style={{ width: "100%" }}
                            value={f.type}
                            onChange={makeLevelUpdater(f.uuid, "type")}
                            options={FORMULA_TYPE_OPTIONS}
                            disabled
                          />
                        </Popover>
                      </TableCell>
                      <TableCell style={{ padding: "10px", height: "25px" }}>
                        <Select
                          style={{ minWidth: "128px", width: "100%" }}
                          options={getFieldTypeOptions(
                            f,
                            dateSelected,
                            figureSelected,
                            false,
                          )}
                          onChange={makeLevelUpdater(f.uuid, "fieldType")}
                          value={f.fieldType}
                          allowClear
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={100}>
                      <Empty
                        imageStyle={{ height: "50px" }}
                        description="Нет входящих полей"
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <div>
            <span style={{ fontWeight: "bold" }}>Исходящий поток</span>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "100px",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Идентификатор
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "250px",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Описание
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "100px",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Тип
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.formula.length > 0 ? (
                  state.formula.map((f, i) => (
                    <TableRow key={i}>
                      <TableCell style={{ padding: "10px", height: "25px" }}>
                        <Popover content={f.id}>
                          <Input value={f.id} disabled />
                        </Popover>
                      </TableCell>
                      <TableCell style={{ padding: "10px", height: "25px" }}>
                        <Popover content={f.desc}>
                          <Input value={f.desc} disabled />
                        </Popover>
                      </TableCell>
                      <TableCell style={{ padding: "10px", height: "25px" }}>
                        <Popover content={f.type}>
                          <Select
                            style={{ width: "100%" }}
                            value={f.type}
                            options={FORMULA_TYPE_OPTIONS}
                            disabled
                          />
                        </Popover>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={100}>
                      <Empty
                        imageStyle={{ height: "50px" }}
                        description="Нет входящих полей"
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </SContent>
  );
};

export default AnalysisWindow;

const FORMULA_TYPE_OPTIONS = [
  {
    label: "Строка",
    options: [
      { value: "STRING", label: "STRING" },
      { value: "TEXT", label: "TEXT" },
    ],
  },
  {
    label: "Число",
    options: [
      { value: "INTEGER", label: "INTEGER" },
      { value: "DECIMAL", label: "DECIMAL" },
    ],
  },
  {
    label: "Дата и время",
    options: [
      { value: "DATE", label: "DATE" },
      { value: "DATETIME", label: "DATETIME" },
    ],
  },
  {
    label: "Булевы",
    options: [{ value: "BOOL", label: "BOOLEAN" }],
  },
];

const getFieldTypeOptions = (
  f: MappingField,
  skipDate: boolean,
  skipFigure: boolean,
  skipAttribute: boolean,
): { value: string; label: string; disabled?: boolean }[] => [
  {
    value: "DATE",
    label: "Дата",
    disabled: skipDate || !(f.type === "DATE" || f.type === "DATETIME"),
  },
  {
    value: "ATTRIBUTE",
    label: "Атрибут",
    disabled: false,
  },
  {
    value: "FIGURE",
    label: "Показатель",
    disabled: !(f.type === "INTEGER" || f.type === "DECIMAL"),
  },
];

const PERIODS = [
  {
    value: "DAY",
    label: "День",
  },
  {
    value: "WEEK",
    label: "Неделя",
  },
  {
    value: "MONTH",
    label: "Месяц",
  },
  {
    value: "QUARTER",
    label: "Квартал",
  },
  {
    value: "YEAR",
    label: "Год",
  },
];

const MISSING_THRESHOLD_TYPES = [
  {
    label: `Количество наблюдений`,
    value: `QUANTITY`,
  },
  {
    label: `Доля от выборки`,
    value: `PERCENTAGE`,
  },
];
