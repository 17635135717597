import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Node } from "@xyflow/react";
import { Select, notification } from "antd";
import Empty from "antd/lib/empty";
import { produce } from "immer";
import { useContext, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import LevelService from "../../../../../entities/model/LevelService";
import ModelService from "../../../../../entities/model/ModelService";
import VersionService from "../../../../../entities/model/VersionService";
import { fuzzyIsIn } from "../../../../../shared/helper/comparison";
import { useICState } from "../../state";
import { Context, SContent } from "../components";
import { MappingField } from "../utils";

enum VersionType {
  BASE = "base",
  VARIABLE = "variable",
  CONSTANT = "constant",
}

type Option = {
  value: string;
  label: string;
};

type Props = {
  id: string;
};

type Version = {
  value: string;
  type: VersionType;
};

type State = {
  model: string;
  level: string;
  version: Version;
  formula: MappingField[];
};

const BASE_VERSION_ID = "__BASE__";
const BASE_VERSION_NAME = "🧱 Базовая";

const BASE_VERSION: Version = {
  value: BASE_VERSION_ID,
  type: VersionType.BASE,
};

const initialState: State = {
  model: "",
  level: "",
  version: BASE_VERSION,
  formula: [],
};

const PlanXFigureInputWindow: React.FC<Props> = ({ id }) => {
  const { nodes, variables, setNodes } = useICState();
  const { actions } = useContext(Context);

  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    actions.save = () => {
      setNodes(
        produce((nodes: Node[]) => {
          const node = nodes.find((node) => node.id === id);
          node.data.model = state.model;
          node.data.level = state.level;
          node.data.version = state.version;
          node.data.formula = state.formula;
        }),
      );
    };
  });

  useEffect(() => {
    const node = nodes.find((node) => node.id === id);

    if (node) {
      setState(
        produce((state) => {
          state.model = node.data.model;
          state.level = node.data.level;
          state.formula = node.data.formula;
          state.version = !node.data.version
            ? BASE_VERSION
            : // string comparison here for backward compatibility
              // because in previous versions version was stored as a string
              typeof node.data.version !== "string"
              ? node.data.version
              : node.data.version.startsWith("VARIABLE_")
                ? { value: node.data.version, type: VersionType.VARIABLE }
                : { value: node.data.version, type: VersionType.CONSTANT };
        }),
      );
    }
  }, [id]);

  const setModelSelected = (model: string) => {
    setState(
      produce((state) => {
        state.model = model;
      }),
    );
  };

  const setLevelSelected = (level: string) => {
    setState(
      produce((state) => {
        state.level = level;
      }),
    );
  };

  const setVersionSelected = (version: Version) => {
    setState(
      produce((state) => {
        state.version = version;
      }),
    );
  };

  const setFormula = (fields: MappingField[]) => {
    setState(
      produce((state) => {
        state.formula = fields;
      }),
    );
  };

  const [modelOptions, setModelOptions] = useState<Option[]>([]);
  const [levelOptions, setLevels] = useState<Option[]>([]);
  const [versions, setVersions] = useState<Option[]>([]);

  const versionOptions = useMemo(
    () => [
      {
        label: BASE_VERSION_NAME,
        value: BASE_VERSION_ID,
        data: {
          value: BASE_VERSION_ID,
          type: VersionType.BASE,
        },
      },
      ...variables.map((variable) => ({
        label: `🧮 ${variable.id}`,
        value: `VARIABLE_${variable.id}`,
        data: {
          type: VersionType.VARIABLE,
          value: variable.id,
        },
      })),
      ...versions.map((version) => ({
        ...version,
        data: {
          type: VersionType.CONSTANT,
          value: version.value,
        },
      })),
    ],
    [versions, variables],
  );

  const getModelList = async () => {
    const getModels = await ModelService.getAll();
    if (getModels.code === 1) {
      setModelOptions((prevOptions: any) =>
        getModels.data.map((el: any) => ({
          label: el.name,
          value: el.id,
        })),
      );
    } else {
      notification.error({
        message: getModels.text,
        description: `Код ответа: ${getModels.code}`,
      });
    }
  };

  useEffect(() => {
    getModelList();
  }, []);

  const getLevelList = async () => {
    if (!state.model || state.model === "") {
      return;
    }
    const getLevels = await LevelService.getAll(state.model);
    if (getLevels.code === 1) {
      setLevels((prevOptions: any) =>
        getLevels.data.map((el: any) => ({
          label: el.name,
          value: el.id,
        })),
      );
    }
  };

  useEffect(() => {
    getLevelList();
  }, [state.model]);

  const getVersionList = async () => {
    if (
      !state.model ||
      state.model === "" ||
      !state.level ||
      state.level === ""
    ) {
      return;
    }

    const getVersions = await VersionService.getAll({
      modelId: state.model,
      levelId: state.level,
    });

    if (getVersions.code === 1) {
      setVersions(
        getVersions.data.map((version: any) => ({
          label: version.name,
          value: version.id,
        })),
      );
    }
  };

  useEffect(() => {
    getVersionList();
  }, [state.model, state.level]);

  const getFields = async () => {
    if (!state.level || state.level === "") {
      return;
    }

    const getAttributes = await LevelService.getAttributes(
      state.model,
      state.level,
    );
    if (getAttributes.code !== 1) {
      return;
    }
    const keyAttributes = getAttributes.data.filter((el: any) => el.key);

    const getFigures = await LevelService.getFigures(state.model, state.level);
    if (getFigures.code !== 1) {
      return;
    }

    const getPeriod = await LevelService.getPeriod(state.model, state.level);
    if (getPeriod.code !== 1) {
      return;
    }

    const fields: MappingField[] = [];
    for (const key of keyAttributes) {
      fields.push({
        uuid: uuidv4(),
        id: key.attributeId,
        desc: key.attributeName,
        type: "STRING",
      });
    }

    if (getPeriod.data > 0) {
      fields.push({
        uuid: uuidv4(),
        id: "DATE",
        desc: "Дата",
        type: "DATE",
      });
    }

    for (const figure of getFigures.data) {
      fields.push({
        uuid: figure.uuid,
        id: "F_" + figure.id,
        desc: figure.name,
        type: "DECIMAL",
      });
    }

    setFormula(fields);
  };

  useEffect(() => {
    getFields();
  }, [state.level]);

  return (
    <SContent>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Модель</div>
          <Select
            value={state.model}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setModelSelected(el)}
            options={modelOptions}
            showSearch={true}
            filterOption={(input, option?: Option) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Уровень</div>
          <Select
            value={state.level}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setLevelSelected(el)}
            options={levelOptions}
            showSearch={true}
            filterOption={(input, option?: Option) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Версия</div>
          <Select
            value={state.version.value}
            style={{ width: "300px", color: "black" }}
            onChange={(version) =>
              setVersionSelected(
                versionOptions.find((x) => x.value === version).data,
              )
            }
            options={versionOptions}
            showSearch={true}
            filterOption={(input, option?: Option) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>
                Идентификатор
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Описание</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Тип</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.formula?.length > 0 ? (
              state.formula.map((el: MappingField, ind: number) => (
                <TableRow key={ind}>
                  <TableCell>{el.id}</TableCell>
                  <TableCell>{el.desc}</TableCell>
                  <TableCell>{el.type}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={100}>
                  <Empty
                    imageStyle={{ height: "50px" }}
                    description="Нет исходящих полей"
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </SContent>
  );
};

export default PlanXFigureInputWindow;
