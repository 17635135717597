import { useQuery } from "@tanstack/react-query";
import { Node } from "@xyflow/react";
import { Button, Input, Select, Table } from "antd";
import { TableProps } from "antd/lib";
import { produce } from "immer";
import { useContext, useEffect, useMemo, useState } from "react";
import AgentService from "../../../../../entities/model/AgentService";
import FlowService from "../../../../../entities/model/FlowService";
import { fuzzyIsIn } from "../../../../../shared/helper/comparison";
import { useICState } from "../../state";
import { Context, SContent } from "../components";

type Props = {
  id: string;
};

type PaginationResponse<T> = {
  count: number;
  rows: T[];
};

const Menu: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
      {children}
    </div>
  );
};

const MenuItem: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "15px",
        alignItems: "center",
      }}
    >
      {children}
    </div>
  );
};

enum ParameterType {
  CONSTANT = "constant",
  VARIABLE = "variable",
}

type Parameter<T = any> = {
  type: ParameterType;
  value: T;
};

type State = {
  flow: Parameter<string>;
  agent: Parameter<string>;
  variables: any[];
};

const initialState: State = {
  flow: { type: ParameterType.CONSTANT, value: "" },
  agent: { type: ParameterType.CONSTANT, value: "" },
  variables: [],
};

const LaunchFlow: React.FC<Props> = ({ id }) => {
  const { nodes, variables, setNodes } = useICState();
  const { actions } = useContext(Context);

  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    actions.save = () => {
      setNodes(
        produce((nodes: Node[]) => {
          const node = nodes.find((node) => node.id === id);
          node.data.flow = state.flow;
          node.data.agent = state.agent;
          node.data.variables = state.variables;
        }),
      );
    };
  });

  const setStateKeyValue =
    (key: string) => (value: any | ((value: any) => any)) => {
      setState(
        produce((state) => {
          state[key] = typeof value === "function" ? value(state[key]) : value;
        }),
      );
    };

  const setFlow = setStateKeyValue("flow");
  const setAgent = setStateKeyValue("agent");
  const setVariables = setStateKeyValue("variables");

  useEffect(() => {
    const node = nodes.find((node) => node.id === id);

    if (node) {
      setState(
        produce((state) => {
          state.flow =
            (typeof node.data.flow === "string"
              ? { type: ParameterType.CONSTANT, value: node.data.flow }
              : node.data.flow) ?? initialState.flow;

          state.agent =
            (typeof node.data.agent === "string"
              ? { type: ParameterType.CONSTANT, value: node.data.agent }
              : node.data.agent) ?? initialState.agent;

          state.variables = node.data.variables ?? initialState.variables;
        }),
      );
    }
  }, [id]);

  const { data: { rows: flows = [] } = {} } = useQuery<
    PaginationResponse<{ id: string; project: string }>
  >({
    queryKey: ["flows", "launchFlow"],
    queryFn: async () => {
      const response = await FlowService.getAll();

      if (response.code !== 1) {
        throw new Error(
          `Ошибка при попытке загрузки списка заданий интеграции: "${response.text}"`,
        );
      }

      return response.data;
    },
  });

  const flowOptions = useMemo(
    () => [
      ...flows.map(({ id }) => ({
        value: id,
        label: id,
        data: {
          type: ParameterType.CONSTANT,
          value: id,
        },
      })),
      ...variables.map(({ id }) => ({
        value: `VARIABLE_${id}`,
        label: `🧮 ${id}`,
        data: {
          type: ParameterType.VARIABLE,
          value: id,
        },
      })),
    ],
    [flows, variables],
  );

  const { data: agents = [] } = useQuery<{ id: string }[]>({
    queryKey: ["integration-agents", "launchFlow"],
    queryFn: async () => {
      const response = await AgentService.getAll();

      if (response.code !== 1) {
        throw new Error(
          `Ошибка при попытке загрузки списка агентов интеграции: "${response.text}"`,
        );
      }

      return response.data;
    },
  });

  const agentOptions = useMemo(
    () => [
      ...agents.map(({ id }) => ({
        value: id,
        label: id,
        data: {
          type: ParameterType.CONSTANT,
          value: id,
        },
      })),
      ...variables.map(({ id }) => ({
        value: `VARIABLE_${id}`,
        label: `🧮 ${id}`,
        data: {
          type: ParameterType.VARIABLE,
          value: id,
        },
      })),
    ],
    [agents, variables],
  );

  async function fetchVariables(flow: string) {
    const response = await FlowService.getOne(flow);

    if (response.code !== 1) {
      throw new Error(`Ошибка при попытке загрузки потока: "${response.text}"`);
    }

    return response.data;
  }

  const onFlowSelect = (flow: Parameter<string>) => {
    setFlow(flow);

    if (flow.type === ParameterType.CONSTANT) {
      fetchVariables(flow.value).then((data) => setVariables(data.variables));
    } else {
      setVariables([]);
    }
  };

  const variableColumns: TableProps<any>["columns"] = [
    {
      title: "Переменная",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Значение",
      dataIndex: "",
      key: "value",
      render: (_, record) => (
        <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
          <Input
            defaultValue={record.defaultValue}
            value={record.value}
            onChange={({ target: { value } }) =>
              setVariables(
                produce((xs: any) => {
                  xs.forEach((x: any) => {
                    if (x.id === record.id) {
                      x.value = value;
                    }
                  });
                }),
              )
            }
          />
          <Button
            onClick={() => {
              setVariables(
                produce((xs: any) => {
                  xs.forEach((x: any) => {
                    if (x.id === record.id) {
                      x.value = x.defaultValue;
                    }
                  });
                }),
              );
            }}
          >
            ↩️
          </Button>
        </div>
      ),
    },
  ];

  return (
    <SContent>
      <Menu>
        <MenuItem>
          <div style={{ width: "150px", fontWeight: "bold" }}>Выбор потока</div>
          <Select
            value={state.flow.value}
            style={{ width: "300px", color: "black" }}
            onChange={(id) =>
              onFlowSelect(flowOptions.find((x) => x.value === id)!.data)
            }
            options={flowOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </MenuItem>
        <MenuItem>
          <div style={{ width: "150px", fontWeight: "bold" }}>Выбор агента</div>
          <Select
            value={state.agent.value}
            style={{ width: "300px", color: "black" }}
            onChange={(id) =>
              setAgent(agentOptions.find((x) => x.value === id)!.data)
            }
            options={agentOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </MenuItem>
      </Menu>
      <div style={{ marginTop: "15px" }}>
        <Table
          columns={variableColumns}
          dataSource={state.variables}
          pagination={false}
        />
      </div>
    </SContent>
  );
};

export default LaunchFlow;
